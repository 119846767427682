import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/images/logo.png");
;
import(/* webpackMode: "eager" */ "/app/src/ui/Footer/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/ui/Footer/Head/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/ui/Footer/Contacts/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/ui/Footer/Socials/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/app/src/ui/Navigation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RedirectLink"] */ "/app/src/ui/RedirectLink/RedirectLink.tsx");
