'use client'
import {CSSProperties} from "react";
import {RoleTypeExtended} from "@/interfaces/RoleType";


export const RedirectLink = ({href, text, roleType, className, style}: {
    href: string,
    text: string,
    roleType: RoleTypeExtended,
    className?: string,
    style?: CSSProperties
}) => {

    const handleClick = () => {
        if (roleType !== localStorage.getItem('profileType')) {
            localStorage.setItem('profileType', roleType === 'executor' ? 'executor' : 'customer')
        }
    }

    return <a
        target={'_blank'}
        href={href}
        className={className}
        style={style}
        onClick={handleClick}
    >
        {text}
    </a>
}