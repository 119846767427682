'use client'
import Link from 'next/link'
import s from './styles.module.scss'
import { PageType } from '@/interfaces/PageType'

interface IProps {
  pageType?: PageType,
  navigationItems: {
    text: string,
    href: string
    pageType?: PageType
  }[]
}

export const Navigation = ({ navigationItems, pageType }: IProps) => {

  return <nav className={s.navigation}>
    <ul className={s.list}>
      {navigationItems.map(({ text, href, pageType: currentPageType }) => {
        return <li key={href}>
          <Link
            className={`${s.link} ${pageType === currentPageType ? s.active : ''}`}
            href={href}
          >
            {text}
          </Link>
        </li>
      })}
    </ul>
  </nav>
}
